<template>
  <div v-loading="isLoading" class="box-container">
    <div class="absolute cursor-pointer d-print-none top-2 right-2" @click="handleBack">
      <div>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-1">
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="black" />
        </svg>
      </div>
    </div>
    <div class="marin-print"></div>
    <div v-if="isShowWarning" class="cs-message d-print-none" style="background-color: rgb(168, 39, 39); color: white">
      <div class="cs-header">
        <span class="font-bold text-xl">{{ $t("Thanh toán thất bại") }}</span>
        <button @click="() => (isShowWarning = false)" type="button"
          class="bg-transparent text-black fs-24 border-0 p-2" style="outline: none !important">
          <span class="cursor-pointer text-white">&times;</span>
        </button>
      </div>
      <div class="flex gap-4 mt-1">
        <div class="flex items-center">
          <svg width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" fill-rule="evenodd"
              d="M8.429 2.746a.5.5 0 0 0-.858 0L1.58 12.743a.5.5 0 0 0 .429.757h11.984a.5.5 0 0 0 .43-.757zm-2.144-.77C7.06.68 8.939.68 9.715 1.975l5.993 9.996c.799 1.333-.161 3.028-1.716 3.028H2.008C.453 15-.507 13.305.292 11.972l5.993-9.997ZM9 11.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-.25-5.75a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <div class="fs-20">
          {{ $t(messageError) }}
          {{ $t("Vui lòng thanh toán lại đơn hàng ") }}
          <span @click="handlePayment" class="ml-1 underline cursor-pointer">{{
    $t("tại đây.")
  }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end my-2 d-print-none">
      <div v-if="serviceDetail.service_payment_transaction &&
    serviceDetail.service_payment_transaction.status === 1
    " class="d-flex">
        <button class="btn bg-pri bd-pri text-white ml-3" @click="handlePayment">
          {{ $t("Thanh toán") }}
        </button>
      </div>
      <div class="d-flex">
        <button class="btn bg-pri bd-pri text-white ml-3" @click="print">
          {{ $t("In phiếu") }}
        </button>
      </div>
    </div>
    <div class="p-4 cs-border">
      <div class="font-style mb-3">
        <div class="flex justify-between fs-16">
          <div class="text-left" style="max-width: 300px">
            <!-- <div>{{ org.aic }}</div> -->
            <div>
              {{ serviceDetail.workspace && serviceDetail.workspace.org_name }}
            </div>
            <div class="fs-13 capitalize">
              {{ serviceDetail.workspace && serviceDetail.workspace.name }}
            </div>
          </div>
          <div class="text-left normal-case">
            <div>
              <span class="mr-1">{{ $t("Mã BN: ") }}</span>
              <span>{{ serviceDetail.pid }}</span>
            </div>
            <div>
              <span class="mr-1">{{ $t("Ký hiệu: ") }}</span>
              <span></span>
            </div>
            <div>
              <span class="mr-1">{{ $t("Số phiếu: ") }}</span>
              <span>{{ serviceDetail.number_code }}</span>
            </div>
          </div>
        </div>
        <div class="text-center uppercase fs-24 my-3">
          {{ $t("Phiếu thu dịch vụ y tế") }}
        </div>
      </div>

      <div>
        <div class="flex flex-col md:flex-row item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Họ và tên:") }}</span>
            <strong>{{ serviceDetail.person_name }}</strong>
          </div>
          <div class="flex-1 flex gap-4">
            <div>
              <span class="mr-2">{{ $t("Giới tính:") }}</span>
              <span>
                {{ serviceDetail.person_gender === 1 ? "Nam" : "Nữ" }}
              </span>
            </div>
            <div>
              <span class="mr-2">{{ $t("Năm sinh:") }}</span>
              <span>{{ serviceDetail.person_yob }}</span>
            </div>
          </div>
        </div>
        <div class="flex item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Đối tượng:") }}</span>
            <span></span>
          </div>
          <div class="flex-1">
            <span class="mr-2">{{ $t("Số thẻ:") }}</span>
            <span></span>
          </div>
        </div>
        <div>
          <span class="mr-2">{{ $t("Địa chỉ:") }}</span>
          <span>{{ serviceDetail.address || "" }}</span>
        </div>
        <div>
          <span class="mr-2">{{ $t("Khoa,phòng:") }}</span>
          <span>
            {{ serviceDetail.workspace && serviceDetail.workspace.name }}
          </span>
        </div>
        <div class="flex item-center gap-2">
          <div class="flex-1">
            <span class="mr-2">{{ $t("Chẩn đoán:") }}</span>
            <span>{{ serviceDetail.diagnostic }}</span>
          </div>
          <div class="flex-1 text-right">
            <strong class>{{ $t("ĐVT: đồng") }}</strong>
            <span></span>
          </div>
        </div>
      </div>
      <table class="mb-3 mt-2">
        <tr class="font-style">
          <th width="4%">{{ $t("STT") }}</th>
          <th width="35%">{{ $t("Tên dịch vụ") }}</th>
          <th width="6%">{{ $t("SL") }}</th>
          <th width="15%">{{ $t("Đơn giá") }}</th>
          <th width="25%">{{ $t("Chi phí khác") }}</th>
          <th width="15%">{{ $t("Thành tiền") }}</th>
        </tr>
        <tr class="table-row" v-for="(item, i) in indicationServices" :key="i + 'indicationService'">
          <td>{{ i + 1 }}</td>
          <td class="text-left">{{ getServiceName(item) }}</td>
          <td>{{ item.qty }}</td>
          <td class="text-right">{{ getPrice(item) }}</td>
          <td class="text-left">
            <ul class="my-1">
              <li
                v-for="(other_expenses, index) in item.service_catalog && item.service_catalog.product_info.other_expenses"
                :key="index + 'other_expenses'" style="width: fit-content">
                {{ other_expenses.name }}: {{ appUtils.numberFormat(other_expenses.value) }} </li>
            </ul>
          </td>
          <td class="text-right" style="text-align: right">
            {{ appUtils.numberFormat(item.transaction_amount || 0) }}
          </td>

        </tr>

        <tr class="table-row">
          <td colspan="5" style="text-align: right" class="font-bold">
            {{ $t("Tổng tiền") }}
          </td>
          <td colspan="1" style="text-align: right" class="text-right font-bold">
            {{ appUtils.numberFormat(serviceDetail.amount || 0) }}
          </td>
        </tr>
      </table>
      <div class="flex justify-between">
        <div>
          <strong>{{ $t("Bằng chữ: ") }}</strong>
          <span class="cs-text inline-block">
            <p>{{ appUtils.numberToWords(serviceDetail.amount || 0) }}</p>
          </span>
        </div>
        <div>
          <strong>{{ $t("Trạng thái: ") }}</strong>
          <span class="cs-text inline-block">
            <p>{{ paymentStatus }}</p>
          </span>
        </div>
      </div>
      <div class="box-footer mt-4">
        <div class="text-center">
          <span>{{ getHMDMYVN(serviceDetail.created_at) }}</span>
          <div class="font-style">{{ $t("Người lập") }}</div>
          <span class="italic">{{ $t("Ký, ghi rõ họ tên") }}</span>
          <div class="mt-16">
            <div class="sign-text">
              {{ serviceDetail.created_by && serviceDetail.created_by.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import appUtils from '@/utils/appUtils'
import {
  PAYMENT_TRANSACTION_STATUS,
  TRANSACTION_ERROR
} from '@/utils/constants'
import { PRODUCT_TYPE } from '@/utils/constant'

export default {
  name: 'ReceiptDetail',
  data () {
    return {
      serviceDetail: {},
      indicationServices: [],
      appUtils,
      isLoading: false,
      isShowWarning: false,
      messageError: ''
    }
  },
  watch: {},
  computed: {
    paymentStatus () {
      return this.serviceDetail?.service_payment_transaction?.status === 2
        ? 'Đã thanh toán'
        : 'Chưa thanh toán'
    },
    date () {
      const dateQuery = this.$route.query.date
      const date = moment(dateQuery)
      return date.isValid()
        ? `${date.format('dddd')} ngày ${date.format('DD.MM.YYYY')}`
        : date
    },
    org () {
      const org = this.$globalClinic
      return {
        name: org?.name,
        org_name: org?.organization?.name,
        aic: org?.organization?.authority_in_charge
      }
    }
  },
  async mounted () {
    await this.getDetailPaymentReceipt()
    this.onConnectSocket()
  },
  methods: {
    async createIndicationOrder () {
      if (this.serviceDetail?.status === 2) return

      try {
        this.isLoading = true
        const serviceID = this.$route.params.id
        const params = {
          order_date: Date.now(),
          order_by: this.$user.id,
          slip_id: Number(serviceID),
          is_emergency: 1
        }
        const res = await this.$rf
          .getRequest('AuthRequest')
          .createSlipOrder(params)
        return res.data
      } catch (error) {
        console.log(error)
        this.$message({
          message: this.$t('Lỗi tạo đơn hàng'),
          type: 'success'
        })
      } finally {
        this.isLoading = false
      }
    },
    formatYear (date) {
      return window.moment(date).format('YYYY')
    },
    async getDetailPaymentReceipt () {
      try {
        const serviceID = this.$route.params.id

        const res = await this.$rf
          .getRequest('AuthRequest')
          .getDetailPaymentReceipt(serviceID)
        this.serviceDetail = res.data || {}
        this.indicationServices = res.data?.service_transactions || []

        if (res.data?.payment_his?.length) {
          const latestPayment =
            res.data?.payment_his[res.data?.payment_his?.length - 1]

          const responseCode =
            this.$route.query.vpc_TxnResponseCode ||
            latestPayment?.current_status

          this.handleCheckResponsePayment(responseCode)
        }

        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    handleBack () {
      this.$router.replace({
        name: 'PaymentReceipts'
      })
    },
    print () {
      const tempTitle = document.title
      document.title = `Danh sach dich vu_${moment().format('DDMMYYYY')}.pdf`
      window.print()
      document.title = tempTitle
    },

    getHMDMYVN (date) {
      const string = moment(date).locale(this.$lang)
      if (this.$lang === 'vi') {
        // let hm = dt.clone().format('HH:mm')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')
        return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return ''
      }
    },
    convertHtmlToPlainText (html) {
      // Create a new div element
      if (!html) return ''
      const tempDivElement = document.createElement('div')
      // Set the HTML content with the given value
      tempDivElement.innerHTML = html
      // Retrieve the text property of the element
      return tempDivElement.textContent || tempDivElement.innerText || ''
    },
    getPid (data) {
      return (
        data?.medical_record?.person_ref_id ||
        (data?.medical_record?.person && data?.medical_record?.person?.pid) ||
        data?.operation?.code ||
        data?.concluding?.profile_code
      )
    },
    getDiagnose (item) {
      return (
        (item.operation && item?.operation?.diagnostic) ||
        (item.multilang && item?.multilang[0]?.patient_diagnostic) ||
        item?.patient_diagnostic
      )
    },
    getEMRCode (item) {
      return item?.medical_record?.ref_id || item?.person?.doc_no
    },
    getServiceName (item) {
      if (item?.type_service === 1) {
        return item.service_catalog && item.service_catalog.name
      } else {
        return item.local_service && item.local_service.local_name
      }
    },
    getPrice (item) {
      if (item?.type_service === 1) {
        if (item?.service_catalog?.product_info?.type === PRODUCT_TYPE.SERVICE) {
          return (
            appUtils.numberFormat(item?.service_catalog?.product_info?.default_price || 0)
          )
        } else {
          return (
            item.service_catalog &&
            appUtils.numberFormat(item?.service_catalog?.price || 0)
          )
        }
      } else {
        return (
          item.local_service &&
          appUtils.numberFormat(item?.local_service?.local_price || 0)
        )
      }
    },
    async onConnectSocket () {
      // const self = this
      // Have this in case you stop running your laravel echo server
      const eventName = this.$route.query.vpc_MerchTxnRef

      // const eventName = "IMARMTcwODE4NjYxODg3Mg";
      const channelName = `service_clinic.clinic${this.serviceDetail?.workspace?.id}`
      const self = this

      if (this.$conectedEcho && this.$user && eventName) {
        // const user = this.$user

        const responseCode = this.$route.query.vpc_TxnResponseCode
        if (
          self.serviceDetail?.service_payment_transaction?.status !==
          PAYMENT_TRANSACTION_STATUS[2].value &&
          responseCode === '0'
        ) {
          this.isLoading = true
        }

        this.$echo
          .channel(channelName)
          .listen('ServiceOPEvents', async (data) => {
            if (
              data?.data?.MetaData?.vpc_MerchTxnRef === eventName &&
              self.serviceDetail?.service_payment_transaction?.status !==
              PAYMENT_TRANSACTION_STATUS[2].value
            ) {
              const response = await this.getDetailPaymentReceipt()

              if (
                response?.service_payment_transaction?.status ===
                PAYMENT_TRANSACTION_STATUS[2].value
              ) {
                this.$message({
                  message: this.$t('Thanh toán phiếu thu thành công'),
                  type: 'success'
                })

                // create order
                await this.createIndicationOrder()
                this.$router.replace({
                  name: 'ReceiptDetail',
                  params: {
                    id: this.$route.params.id
                  }
                })
              }

              setTimeout(() => {
                this.isLoading = false
              }, 1000)
            }
          })
      }
    },
    handleCheckResponsePayment (responseCode) {
      if (responseCode && Number(responseCode) !== 0) {
        this.isShowWarning = true
        this.messageError =
          TRANSACTION_ERROR.find((item) => item.value === responseCode)
            ?.label || ''
        this.isLoading = false
      }
    },
    async paymentWithOnePay () {
      if (
        !this.serviceDetail?.id ||
        !this.serviceDetail?.service_transaction_slip_code
      ) {
        return
      }

      try {
        const params = {
          orderType: 6, // Appt
          orderId: this.serviceDetail?.id,
          tx_code: this.serviceDetail?.service_transaction_slip_code,
          returnUrl: `${window.location.origin}/#/receipt-detail/${this.serviceDetail?.id}`
        }

        const response = await this.$rf
          .getRequest('AuthRequest')
          .postRequestPayment(params)

        if (response.data?.url) {
          window.location.replace(response.data.url)

          // window.open(response.data.url)
        } else {
          this.$toast.open({
            message: 'Thanh toán thất bại',
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async handlePayment () {
      this.$confirm(
        this.$t('Bạn có chắc chắn muốn thanh toán phiếu thu không?'),
        this.$t('Xác nhận thanh toán'),
        {
          cancelButtonText: this.$t('Huỷ'),
          confirmButtonText: this.$t('Xác nhận'),
          dangerouslyUseHTMLString: true
        }
      )
        .then((_) => {
          this.paymentWithOnePay()
        })
        .catch((_) => { })
    }
  }
}
</script>

<style scoped lang="scss">
.box-container {
  color: black;
  width: 1125px;
  padding: 0 20px;
  margin: auto;

  ::v-deep * {
    font-family: "Times New Roman", "Times", "Tinos", serif !important;
  }
}

.box-footer {
  display: flex;
  justify-content: end;
  margin-right: 80px;
}

.font-style {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

table,
th,
td {
  color: black;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

table {
  width: 100%;
}

.table-row>td {
  padding: 0 5px;
}

.table-row,
.sign-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.sign-input {
  outline: 0;
  border-width: 0 0 1px;
  text-align: center;
}

.cs-note {
  display: none;
}

.cs-text {
  text-transform: lowercase;

  p::first-letter {
    text-transform: capitalize;
  }
}

.cs-border {
  border: 1px solid rgba(101, 109, 119, 0.16);
}

@media print {
  #BrowserPrintDefaults {
    display: none;
  }

  thead,
  tfoot {
    display: none !important;
  }

  @page {
    size: auto A4 landscape;
    margin: 0;
    margin-bottom: 10mm;
    margin-top: 10mm;
  }

  // .container,
  // .box-container {
  //   max-width: unset;
  //   width: 1150px;
  // }
  .sign-input {
    display: none;
  }

  .sign-text {
    display: block;
    font-weight: bold;
  }

  .word-break {
    word-break: break-all;
  }

  .marin-print {
    display: none;
  }

  .cs-note {
    display: block;
  }

  .cs-border {
    padding-top: 0px !important;

    border: none !important;
  }
}

::v-deep {
  .cs-input {
    input {
      background-color: #dae8fd !important;
      padding: 4px;
    }
  }
}

.cs-message {
  padding: 12px 20px !important;
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;

  .cs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 30px;
      padding: 0 !important;
      line-height: 1;
    }
  }

  .cs-button {
    background-color: #20419b;
    padding: 8px !important;
    margin-left: auto !important;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}

@media (max-width: 560px) {
  .marin-print {
    margin-top: 60px;
  }
}
</style>
